import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import RequestPasswordReset from './pages/RequestPasswordReset';
import ResetPassword from './pages/ResetPassword';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate  to='/login' />} />
      <Route path='/login' element={<Login />} />
      <Route path='/forgotpw' element={<RequestPasswordReset />} />
      <Route path='/resetpassword' element={<ResetPassword />} />
    </Routes>
  );
}

export default App;
