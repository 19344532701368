import { gql, useMutation } from "@apollo/client";
import { Button, Cell, Colors, ExternalRedirect, Form, Grid, Icon, Icons, Logo, StyledHeading, StyledParagraph, TextField, useAuthState, useForm, View } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import { useState } from "react";

type RequestPasswordResetFormType = {
  email: string
}

const containerStyle: CSSProperties = {
  alignItems: 'center',
  height: '100vh',
  justifyContent: 'space-between',
  padding: '40px 72px',
  '@media (max-width: 767px)': {
    padding: '40px 24px',
  },
}

const iconStyle = {
  fill: Colors.primary500,
  color: Colors.primary500,
  height: '128px',
  width: '128px',
  '@media (max-width: 767px)': {
    height: '92px',
    width: '92px',
  },
}

const REQUEST_PASSWORD_RESET = gql`
mutation RequestPasswordReset($email: String!) {
  success: requestPasswordReset(email: $email)
}
`;

type RequestPasswordResetResponse = {
  success: boolean;
}

export default function RequestPasswordReset() {
  const { state } = useAuthState();
  const [hasRequestedReset, setHasRequestedReset] = useState<boolean>(false);
  const [requestPasswordReset] = useMutation<RequestPasswordResetResponse>(REQUEST_PASSWORD_RESET, {
    onCompleted: () => {
      setHasRequestedReset(true);
    }
  });

  const requestResetForm = useForm<RequestPasswordResetFormType>({
    initialValues: {
      email: '',
    },
    onSubmit: (values: RequestPasswordResetFormType) => {
      requestPasswordReset({
        variables: {
          email: values.email,
        },
      });
    }
  });

  if (state.user) {
    return <ExternalRedirect to='https://start.barscience.us' />
  }

  if (hasRequestedReset) {
    return (
      <Grid>
        <Cell lg={6} lgOffset={4} md={6} mdOffset={2} sm={4}>
          <View style={containerStyle}>
            <View>
              <Logo />
            </View>
            <View style={{ alignItems: 'center', gap: '32px', width: '100%' }}>
              <Icon size='large' icon={Icons.CircleCheckmark} style={iconStyle} />
              <View style={{ gap: '16px' }}>
                <StyledHeading tag='h4' style={{ textAlign: 'center' }}>Success!</StyledHeading>
                <StyledParagraph style={{ textAlign: 'center' }}>
                  We’ll send you a password reset link if we find an account associated with the email you provided.
                </StyledParagraph>
              </View>
            </View>
            <View style={{ flexDirection: 'row', gap: '8px', color: Colors.neutral700 }}>
              <Icon size='medium' icon={Icons.Copyright} />
              <StyledParagraph>
                Copyright 2023 Bar Science
              </StyledParagraph>
            </View>
          </View>
        </Cell>
      </Grid>
    );
  }


  return (
    <Grid>
      <Cell lg={6} lgOffset={4} md={6} mdOffset={2} sm={4}>
        <View style={containerStyle}>
          <View>
            <Logo />
          </View>
          <View style={{ gap: '32px', width: '100%' }}>
            <Button variant='tertiary' role='link' href='/login' label='Back to login' leftIcon={Icons.ChevronLeft} />
            <View style={{ gap: '40px', width: '100%' }}>
              <View style={{ gap: '16px' }}>
                <StyledHeading tag='h4'>Forgot your password?</StyledHeading>
                <StyledParagraph>
                  Enter the email address associated with your account, and we’ll send you a link to reset your password.
                </StyledParagraph>
              </View>
              <Form handleSubmit={requestResetForm.handleSubmit}>
                <View style={{ gap: '40px', width: '100%' }}>
                  <TextField name='email' type='email' value={requestResetForm.values.email} error={requestResetForm.errors.email} label='Email' onChange={requestResetForm.handleChange} onValidate={requestResetForm.handleValidate} required />
                  <Button label='Request password reset' variant='primary' role='button' action={() => { }} type='submit' isFullWidth disabled={requestResetForm.hasError || requestResetForm.isLoading} />
                </View>
              </Form>
            </View>
          </View>
          <View style={{ flexDirection: 'row', gap: '8px', color: Colors.neutral700 }}>
            <Icon size='medium' icon={Icons.Copyright} />
            <StyledParagraph>
              Copyright 2023 Bar Science
            </StyledParagraph>
          </View>
        </View>
      </Cell>
    </Grid>
  );
}