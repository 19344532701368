import { gql, useMutation } from "@apollo/client";
import { Button, Cell, Colors, ExternalRedirect, Form, Grid, Icon, Icons, Link, Logo, Strut, StyledHeading, StyledParagraph, TextField, useAuthState, useForm, View } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const containerStyle: CSSProperties = {
  alignItems: 'center',
  height: '100vh',
  justifyContent: 'space-between',
  padding: '40px 72px',
  '@media (max-width: 767px)': {
    padding: '40px 24px',
  },
}

const iconStyle = {
  fill: Colors.primary500,
  color: Colors.primary500,
  height: '128px',
  width: '128px',
  '@media (max-width: 767px)': {
    height: '92px',
    width: '92px',
  },
}

const RESET_PASSWORD_MUTATION = gql`
mutation ResetPassword($input: ResetPasswordInput!) {
  success: resetPassword(input: $input)
}
`;

type ResetPasswordMutationResponse = {
  success: boolean;
}

type ResetPasswordFormType = {
  newPassword: string;
  confirmPassword: string;
}

export default function ResetPassword() {
  const { state } = useAuthState();
  const [searchParams] = useSearchParams();
  const [wasSuccessful, setWasSuccessful] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const [resetPassword] = useMutation<ResetPasswordMutationResponse>(RESET_PASSWORD_MUTATION, {
    onCompleted: (data) => {
      if (data.success) {
        setWasSuccessful(true);
      } else {
        setIsInvalid(true);
      }
    },
    errorPolicy: 'all',
  });

  const resetPasswordForm = useForm<ResetPasswordFormType>({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: (values) => {
      resetPassword({
        variables: {
          input: {
            newPassword: values.newPassword,
            resetToken: searchParams.get('token'),
          },
        },
      });
    },
  });

  const hasUppercaseLetter = (value: string) => {
    if (value.toLowerCase() === value) {
      return false;
    }
    return true;
  }

  const hasLowercaseLetter = (value: string) => {
    if (value.toUpperCase() === value) {
      return false;
    }
    return true;
  }

  const hasSpecialCharacter = (value: string) => {
    const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
    return specialCharacters.test(value);
  }

  const hasAtLeastEightCharacters = (value: string) => {
    if (value.length < 8) {
      return false;
    }
    return true;
  }

  const passwordsMatch = () => {
    return resetPasswordForm.values.newPassword === resetPasswordForm.values.confirmPassword;
  }

  const validateNewPassword = (name: string, value: string) => {
    if (!hasAtLeastEightCharacters(value) || !hasUppercaseLetter(value) || !hasLowercaseLetter(value) || !hasSpecialCharacter(value)) {
      return '';
    }
    return null;
  }

  if (state.user) {
    return <ExternalRedirect to='https://start.barscience.us' />
  }

  if (wasSuccessful) {
    return (
      <Grid>
        <Cell lg={6} lgOffset={4} md={6} mdOffset={2} sm={4}>
          <View style={containerStyle}>
            <View>
              <Logo />
            </View>
            <View style={{ alignItems: 'center', gap: '32px', width: '100%' }}>
              <Icon size='large' icon={Icons.CircleCheckmark} style={iconStyle} />
              <View style={{ gap: '16px' }}>
                <StyledHeading tag='h4' style={{ textAlign: 'center' }}>Success!</StyledHeading>
                <StyledParagraph style={{ textAlign: 'center' }}>
                  You've successfully reset your password. You can now <Link href='/login'>login to your account</Link>.
                </StyledParagraph>
              </View>
            </View>
            <View style={{ flexDirection: 'row', gap: '8px', color: Colors.neutral700 }}>
              <Icon size='medium' icon={Icons.Copyright} />
              <StyledParagraph>
                Copyright 2023 Bar Science
              </StyledParagraph>
            </View>
          </View>
        </Cell>
      </Grid>
    );
  }

  if (isInvalid || !searchParams.get('token')) {
    return (
      <Grid>
        <Cell lg={6} lgOffset={4} md={6} mdOffset={2} sm={4}>
          <View style={containerStyle}>
            <View>
              <Logo />
            </View>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h4' style={{ textAlign: 'center' }}>Oops! That password reset link is invalid.</StyledHeading>
              <StyledParagraph style={{ textAlign: 'center' }}>
                The password reset link you've used is invalid. You can <Link href='/forgotpw'>request a new one here</Link>. Please note that reset links expire after 20 minutes.
              </StyledParagraph>
            </View>
            <View style={{ flexDirection: 'row', gap: '8px', color: Colors.neutral700 }}>
              <Icon size='medium' icon={Icons.Copyright} />
              <StyledParagraph>
                Copyright 2023 Bar Science
              </StyledParagraph>
            </View>
          </View>
        </Cell>
      </Grid>
    );
  }

  return (
    <Grid>
      <Cell lg={4} lgOffset={5} md={6} mdOffset={2} sm={4}>
        <View style={containerStyle}>
          <View>
            <Logo />
          </View>
          <View style={{ gap: '40px', width: '100%' }}>
            <StyledHeading tag='h4'>Reset your password</StyledHeading>
            <Form handleSubmit={resetPasswordForm.handleSubmit}>
              <View style={{ gap: '16px', width: '100%' }}>
                <TextField name='newPassword' label='New Password' type='password' value={resetPasswordForm.values.newPassword} error={resetPasswordForm.errors.newPassword} onChange={resetPasswordForm.handleChange} onValidate={resetPasswordForm.handleValidate} validate={validateNewPassword} required />

                <View style={{ gap: '4px' }}>
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                    {hasAtLeastEightCharacters(resetPasswordForm.values.newPassword) ? <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} /> : <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />}
                    <StyledParagraph style={hasAtLeastEightCharacters(resetPasswordForm.values.newPassword) ? { color: Colors.primary500 } : { color: Colors.error500 }}>Must contain at least 8 characters</StyledParagraph>
                  </View>

                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                    {hasLowercaseLetter(resetPasswordForm.values.newPassword) ? <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} /> : <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />}
                    <StyledParagraph style={hasLowercaseLetter(resetPasswordForm.values.newPassword) ? { color: Colors.primary500 } : { color: Colors.error500 }}>Must contain a lowercase letter</StyledParagraph>
                  </View>

                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                    {hasUppercaseLetter(resetPasswordForm.values.newPassword) ? <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} /> : <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />}
                    <StyledParagraph style={hasUppercaseLetter(resetPasswordForm.values.newPassword) ? { color: Colors.primary500 } : { color: Colors.error500 }}>Must contain an uppercase letter</StyledParagraph>
                  </View>

                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                    {hasSpecialCharacter(resetPasswordForm.values.newPassword) ? <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} /> : <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />}
                    <StyledParagraph style={hasSpecialCharacter(resetPasswordForm.values.newPassword) ? { color: Colors.primary500 } : { color: Colors.error500 }}>Must contain a special symbol</StyledParagraph>
                  </View>

                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                    {passwordsMatch() ? <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} /> : <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />}
                    <StyledParagraph style={passwordsMatch() ? { color: Colors.primary500 } : { color: Colors.error500 }}>Passwords must match</StyledParagraph>
                  </View>
                </View>
                <Strut size='16px' />
                <TextField name='confirmPassword' label='Confirm New Password' type='password' value={resetPasswordForm.values.confirmPassword} error={resetPasswordForm.errors.confirmPassword} onChange={resetPasswordForm.handleChange} onValidate={resetPasswordForm.handleValidate} required />
                <Strut size='24px' />
                <Button label='Change password' role='button' variant='primary' action={() => { }} type='submit' isFullWidth disabled={resetPasswordForm.hasError || resetPasswordForm.isLoading || !passwordsMatch()} />
              </View>
            </Form>
          </View>
          <View style={{ flexDirection: 'row', gap: '8px', color: Colors.neutral700 }}>
            <Icon size='medium' icon={Icons.Copyright} />
            <StyledParagraph>
              Copyright 2023 Bar Science
            </StyledParagraph>
          </View>
        </View>
      </Cell>
    </Grid>
  );
}