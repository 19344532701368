import { gql, useMutation } from '@apollo/client';
import { Button, Cell, Colors, ExternalRedirect, Form, Grid, Icon, Icons, Link, Logo, StyledDisplay, StyledHeading, StyledParagraph, TextField, useAuthState, useForm, View } from '@barscience/global-components';
import { CSSProperties } from 'aphrodite';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const gridStyle: CSSProperties = {
  '@media (min-width: 1524px)': {
    width: '100vw',
  },
  height: '100vh',
}

const heroContainerStyle: CSSProperties = {
  backgroundColor: '#F0FDF0',
  gap: '40px',
  height: '100vh',
  justifyContent: 'center',
  padding: '0 72px',
  '@media (max-width: 767px)': {
    padding: '0 24px',
  },
}

const formContainerStyle: CSSProperties = {
  alignItems: 'center',
  height: '100vh',
  justifyContent: 'space-between',
  padding: '40px 72px',
  '@media (max-width: 767px)': {
    padding: '40px 24px',
  },
}

type LoginInput = {
  email: string;
  password: string;
}

const LOGIN_MUTATION = gql`
mutation LoginMutation($input: LoginInput!) {
  login(input: $input) {
    success
  }
}
`;

type LoginMutationResponse = {
  login: {
    success: boolean;
  } | null;
}

export default function Login() {
  const { state } = useAuthState();
  const [searchParams] = useSearchParams();
  const [wasSuccessful, setWasSuccessful] = useState<boolean>(false);

  const [login, { loading: loginIsLoading, error: loginError }] = useMutation<LoginMutationResponse>(LOGIN_MUTATION, {
    onCompleted: (data) => {
      if (data?.login?.success) {
        setWasSuccessful(true);
      }
    },
    errorPolicy: 'all',
  });

  const loginForm = useForm<LoginInput>({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values: LoginInput) => {
      await login({
        variables: {
          input: {
            email: values.email,
            password: values.password,
          },
        },
      });
    }
  });

  if (wasSuccessful || state.user) {
    if (searchParams.get('continue')) {
      return (
        <ExternalRedirect to={searchParams.get('continue') || 'https://start.barscience.us'} />
      );
    } else {
      if (window.location.href.includes('localhost')) {
        return (
          <ExternalRedirect to={'http://localhost:6007'} />
        );
      } else if (window.location.href.includes('-dev.barscience.us')) {
        return (
          <ExternalRedirect to={'https://start-dev.barscience.us'} />
        );
      } else {
        return (
          <ExternalRedirect to='https://start.barscience.us' />
        );
      }
    }
  }

  return (
    <Grid style={gridStyle}>
      <Cell lg={6} md={6} mdOffset={2} sm={4}>
        <View style={formContainerStyle}>
          <View>
            <Logo />
          </View>
          <View style={{ gap: '60px' }}>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h2'>
                Welcome Back
              </StyledHeading>
              <StyledParagraph>
                Please login to access all of your Bar Science products.
              </StyledParagraph>
            </View>
            <View style={{ gap: '40px' }}>
              <Form handleSubmit={loginForm.handleSubmit}>
                <View style={{ gap: '40px' }}>
                  <TextField label='Email' name='email' type='email' value={loginForm.values.email} error={loginForm.errors.email} onChange={loginForm.handleChange} onValidate={loginForm.handleValidate} required />
                  <TextField label='Password' type="password" name='password' value={loginForm.values.password} error={loginForm.errors.password} onChange={loginForm.handleChange} onValidate={loginForm.handleValidate} required />
                  <Button variant='primary' role='button' label='Login' action={() => { }} type='submit' disabled={loginForm.hasError || loginIsLoading} loading={loginIsLoading} isFullWidth />
                </View>
                {loginError ?
                  loginError?.graphQLErrors[0].extensions.status === 403 ?
                    <StyledParagraph style={{ color: Colors.error500, marginTop: '16px', maxWidth: '400px', minHeight: '16px', textAlign: 'center' }}>Your account has been deactivated. Please contact your employer for assistance.</StyledParagraph>
                    :
                    <StyledParagraph style={{ color: Colors.error500, marginTop: '16px', minHeight: '16px', textAlign: 'center' }}>Incorrect email or password</StyledParagraph>
                  :
                  <StyledParagraph style={{ marginTop: '16px', minHeight: '24px' }}></StyledParagraph>
                }
              </Form>
              <View style={{ alignItems: 'center', width: '100%' }}>
                <Link href='/forgotpw'>Forgot password?</Link>
              </View>
            </View>
          </View>
          <View style={{ flexDirection: 'row', gap: '8px', color: Colors.neutral700 }}>
            <Icon size='medium' icon={Icons.Copyright} />
            <StyledParagraph>
              Copyright 2023 Bar Science
            </StyledParagraph>
          </View>
        </View>
      </Cell>
      <Cell lg={6} lgOffset={7} md={0} sm={0}>
        <View style={heroContainerStyle}>
          <StyledDisplay size='medium'>
            Business made simple.
          </StyledDisplay>
          <StyledParagraph>
            We've turned running your business into a science.
          </StyledParagraph>
          <StyledParagraph>
            Let us handle the operational headaches so you can focus on what really matters---your employees and customers.
          </StyledParagraph>
        </View>
      </Cell>
    </Grid>
  );
}